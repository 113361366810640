import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav } from "react-bootstrap"

class CustomNavbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    var menuList = this.props.menus.map(function(menu){
      return(
        <Link to={menu.node.link} className="navbar-link">
          <Nav.Link as="span" eventKey="login">
            <h6 className="navbar-menu-item">{menu.node.name}</h6>
          </Nav.Link>
        </Link>
      )
    })

    return(
      <Navbar variant="dark" expand="md" id="site-navbar">
        {/* <Container> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/" className="navbar-link">
              <Nav.Link as="span" eventKey="login">
                <h6 className="navbar-menu-item">BRRRLABS</h6>
              </Nav.Link>
            </Link>
          </Nav>
          <Nav className="ml-auto">
            {menuList}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}


export default CustomNavbar
